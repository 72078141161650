import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Iconify } from '../../../../../components/Iconify';
import { ExternalLinkDialogContext } from '../../../contexts/ExternalLinkDialogContext';
import RouterLink from '../components/RouterLink';
import { type NavConfigProps, type NavItemProps } from '../interfaces/types';
import { StyledDotIcon, StyledIcon, StyledItem } from './styles';
import { useGlobalDeps } from '../../useGlobalDeps';

type Props = NavItemProps & {
  config: NavConfigProps;
};

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  onClick,
  ...other
}: Props) {
  const { setIsOpen, setExternalLinkConfig } =
    ExternalLinkDialogContext.useContainer();

  const { tracker } = useGlobalDeps();

  const {
    title,
    path,
    icon,
    info,
    children,
    disabled,
    caption,
    roles,
    endIcon,
    externalLinkDialog,
    onClick: onClickItem,
    event,
  } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      onClick={(e: any) => {
        if (event && tracker) tracker.trackEvent(event);
        if (onClick) onClick(e);
      }}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>
      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement='top-start'>
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}
      {info && (
        <Box component='span' sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}
      {!!children && (
        <Iconify
          width={16}
          icon={
            open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'
          }
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
      {endIcon}
    </StyledItem>
  );

  if (roles && (!config.currentRole || !roles.includes(config.currentRole))) {
    return null;
  }

  if (onClickItem) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClickItem();
        }}
      >
        {renderContent}
      </div>
    );
  }

  if (!!externalLinkDialog && externalLink) {
    return (
      <div
        onClick={() => {
          setExternalLinkConfig({ path, ...externalLinkDialog });
          setIsOpen(true);
        }}
      >
        {renderContent}
      </div>
    );
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target='_blank'
        rel='noopener'
        underline='none'
        color='inherit'
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={path}
      underline='none'
      color='inherit'
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}
