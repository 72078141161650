import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { Iconify } from '../../../../../components/Iconify';
import { ExternalLinkDialogContext } from '../../../contexts/ExternalLinkDialogContext';
import RouterLink from '../components/RouterLink';
import { type NavConfigProps, type NavItemProps } from '../interfaces/types';
import { StyledIcon, StyledItem } from './styles';
import { useGlobalDeps } from '../../useGlobalDeps';

type Props = NavItemProps & {
  hasChild?: boolean;
  config: NavConfigProps;
};

const NavItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      item,
      depth,
      open,
      active,
      externalLink,
      config,
      hasChild = false,
      ...other
    },
    ref,
  ) => {
    const theme = useTheme();
    const { setIsOpen, setExternalLinkConfig } =
      ExternalLinkDialogContext.useContainer();

    const { tracker } = useGlobalDeps();

    const {
      title,
      path,
      icon,
      children,
      disabled,
      caption,
      roles,
      externalLinkDialog,
      onClick: onClickItem,
      event,
    } = item;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        disableGutters
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        config={config}
        onClick={() => {
          if (tracker && event) tracker.trackEvent(event);
        }}
        {...other}
      >
        {icon && (
          <StyledIcon
            size={config.iconSize}
            sx={{
              ...(subItem && { mr: 1.5 }),
            }}
          >
            {icon}
          </StyledIcon>
        )}

        {!(config.hiddenLabel && !subItem) && (
          <ListItemText
            sx={{
              width: 1,
              flex: 'unset',
              ...(!subItem && {
                px: 0.5,
                mt: 0.5,
              }),
            }}
            primary={title}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: 10,
              lineHeight: '16px',
              textAlign: 'center',
              textTransform: 'capitalize',
              fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
              ...(subItem && {
                textAlign: 'unset',
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
                fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
              }),
            }}
          />
        )}

        {caption && (
          <Tooltip title={caption} arrow placement='right'>
            <Iconify
              width={16}
              icon='eva:info-outline'
              sx={{
                color: 'text.disabled',
                ...(!subItem && {
                  top: 11,
                  left: 6,
                  position: 'absolute',
                }),
              }}
            />
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            width={16}
            icon='eva:arrow-ios-forward-fill'
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
            }}
          />
        )}
      </StyledItem>
    );

    // Hidden item by role

    if (roles && (!config.currentRole || !roles.includes(config.currentRole))) {
      return null;
    }

    if (onClickItem) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClickItem();
          }}
        >
          {renderContent}
        </div>
      );
    }

    if (!!externalLinkDialog && externalLink) {
      return (
        <div
          onClick={() => {
            setExternalLinkConfig({ path, ...externalLinkDialog });
            setIsOpen(true);
          }}
        >
          {renderContent}
        </div>
      );
    }

    // External link
    if (externalLink)
      return (
        <Link
          href={path}
          target='_blank'
          rel='noopener'
          underline='none'
          sx={{
            width: 1,
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {renderContent}
        </Link>
      );

    if (hasChild) {
      return renderContent;
    }
    // Default
    return (
      <Link
        component={RouterLink}
        href={path}
        underline='none'
        sx={{
          width: 1,
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );
  },
);

export default NavItem;
