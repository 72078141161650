import {
  DisplayError,
  MaskedTextField,
  PasswordInput,
  TextField,
  phoneMask,
  useAppContext,
} from '@invest-ai/animatrix';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';

import { cpfMask } from '../../../form/components/MaskedTextField/masks/cpfMask';
import { CheckCoupon } from './CheckCoupon';
import { GoogleSignIn } from './GoogleSignIn';
import { SignUpHeader } from './SignUpHeader';
import { TermsAgreement } from './TermsAgreement';
import { useSignUpEmailForm } from './useSignUpEmailForm';
import { useSignUpFormState } from './useSignUpFormState';
import { AppEvents } from '../SignIn/AppEvents';

export const SignUpForm: React.FC = () => {
  const {
    layout: {
      signUp: { labels },
    },
    tracker,
    product,
  } = useAppContext();

  const {
    email,
    setEmail,
    name,
    setName,
    phone,
    setPhone,
    cpf,
    setCpf,
    password,
    setPassword,
    isCouponActive,
    setIsCouponActive,
    prefilledFormData,
  } = useSignUpFormState();

  const {
    handleSubmitEmailSignUpForm,
    errorSignUp,
    loadingSignUp,
    isValid,
    isEmailValid,
    isCPFValid,
  } = useSignUpEmailForm({
    name,
    email,
    password,
    phone,
    cpf,
    isCouponActive,
  });

  const [googleOAuthError, setGoogleOAuthError] = useState<Error | null>(null);
  const showEmailError = !!email && !isEmailValid;
  const showCPFError = !!cpf && !isCPFValid;

  const error = errorSignUp || googleOAuthError || googleOAuthError;

  useEffect(() => {
    if (!tracker) return;
    tracker.trackEvent(AppEvents.SignUpView, { bu: product.name });
  }, []);

  return (
    <>
      <SignUpHeader />
      <Stack
        onSubmit={handleSubmitEmailSignUpForm}
        component='form'
        spacing={2.5}
      >
        <TextField
          name='firstName'
          label={labels?.firstName ?? 'Seu nome completo'}
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name='email'
          label={labels?.email ?? 'Seu email'}
          value={email}
          InputProps={{
            type: 'email',
            autoCapitalize: 'none',
          }}
          disabled={!!prefilledFormData?.email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
          error={showEmailError}
          helperText={showEmailError && 'E-mail inválido'}
        />
        <MaskedTextField
          mask={phoneMask}
          label={labels?.phone ?? 'Seu celular (com DDD)'}
          value={phone}
          onAccept={setPhone}
          InputLabelProps={{ shrink: true }}
        />
        <MaskedTextField
          mask={cpfMask}
          label={labels?.cpf ?? 'Seu CPF'}
          value={cpf}
          onAccept={setCpf}
          InputLabelProps={{ shrink: true }}
          error={showCPFError}
          helperText={showCPFError && 'CPF inválido'}
        />
        <PasswordInput
          label={labels?.password ?? 'Sua senha'}
          onChange={setPassword}
          value={password}
          InputLabelProps={{ shrink: true }}
        />
        <TermsAgreement />
        <CheckCoupon onCouponActiveChange={setIsCouponActive} />
        <LoadingButton
          fullWidth
          color='primary'
          size='large'
          type='submit'
          variant='contained'
          loading={loadingSignUp}
          disabled={!isValid}
        >
          {labels?.submit ?? 'Começar'}
        </LoadingButton>
        <GoogleSignIn onError={setGoogleOAuthError} />
        <DisplayError error={error} />
      </Stack>
    </>
  );
};
